import styled, { createGlobalStyle } from 'styled-components';
import { MobileDrawerModal } from 'containers/AppLayout/AppLayout.style';
import { ModalPopup } from 'components/Popup/Popup.style';
import { Colors, Spacings, Other } from './constants';

export const GlobalStyle = createGlobalStyle`
  :root {
    ${Colors}
    ${Spacings}
    ${Other}
  }

  ${MobileDrawerModal}
  ${ModalPopup}

  .sticky-inner-wrapper {
    z-index: 10;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
`;

export const Content = styled.div`
    width: 700px;
    margin: 20px auto 0 auto;
    min-height: calc(100vh - 90px);
`;

export const TabHeader = styled.div`
  @media (min-width: 768px) {
    padding: 0 20px !important;
  }

  .nav {
    border: none;
    padding-bottom: 20px;
    position: sticky;
    background-color: #fff;
    z-index: 1000;

    .nav-item {
      border: none;

      h4 {
        margin: 0;
        padding-bottom: 5px;
        color: #757575;
      }

      .nav-item:focus {
        outline: none;
      }
    }
  
    .active h4 {
      border-bottom: 2px #EE504F solid;
      color: #EE504F;
      font-weight: 700;
    }

    .nav-item:hover h4 {
      color: #EE504F;
    }

    .nav-item:focus {
      outline: none;
    }
  }

  .tab-content {
    margin: 0 5px;
  }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 30px;
`;

export const Title2 = styled.h2`
    font-weight: 700;
    font-size: 24px;
`;

export const Title3 = styled.h2`
    font-weight: 700;
    font-size: 20px;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 18px;
  color: var(--primary-label-color);
`;

export const Label2 = styled.p`
  font-size: 15px;
  color: var(--secondary-label-color);
`;

export const Subheadline = styled.h4`
  font-size: 14px;
`;

export const Module = styled.div`
  background-color: #fff;
  box-shadow: var(--devo-shadow);
  border-radius: var(--devo-border-radius);

  .loader {
    margin: 0 auto;
  }
`;

type ButtonProps = {
  variant?: "green" | "red" | "red-no-border" | "orange" | "orange-no-border" | "blue" | "blue-no-border";
}
export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: var(--devo-border-radius);
  font-weight: 800;
  transition: all 0.2s;

  &:focus {
    outline: none;
  }

  ${props => () => {
    switch (props.variant) {
      case "green": return `
        background-color: var(--devo-green);
        color: var(--white);
        transition: transform 0.1s;
        &:hover { transform: scale(1.1); }
        &:active { background-color: var(--devo-dark-green); }
      `;

      case "red": return `
        background-color: var(--devo-red);
        color: var(--white);
        &:hover { background-color: var(--devo-dark-red); }
        &:active { background-color: var(--devo-dark-red); }
      `;

      case "red-no-border": return `
        background-color: transparent;
        color: var(--devo-red);
        &:hover { background-color: var(--devo-red); color: var(--white); }
        &:active { background-color: var(--devo-dark-red); color: var(--white); }
      `;

      case "orange": return `
        background-color: var(--devo-orange);
        color: var(--white);
        &:hover { background-color: var(--devo-dark-orange); }
        &:active { background-color: var(--devo-dark-orange); }
      `;

      case "orange-no-border": return `
        background-color: transparent;
        color: var(--devo-orange);
        &:hover { background-color: var(--devo-orange); color: var(--white); }
        &:active { background-color: var(--devo-dark-orange); color: var(--white); }
      `;

      case "blue": return `
        background-color: var(--devo-blue);
        color: var(--white);
        transition: transform 0.1s;
        &:hover { transform: scale(1.1); }
        &:active { background-color: var(--devo-dark-blue); }
      `;

      case "blue-no-border": return `
        background-color: transparent;
        color: var(--devo-blue);
        &:hover { background-color: var(--devo-blue); color: var(--white); }
        &:active { background-color: var(--devo-dark-blue); color: var(--white); }
      `;

      default: return "";
    }
  }}  
`;

type CthProps = {
  width?: number;
}
export const Cth = styled.th<CthProps>`
  text-align: center;
  width: ${props => props.width || 'auto'};
`;