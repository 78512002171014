
const locale = "en-GB";
const shopTimeZone = "Europe/London";

// ------------------------------
// Properties
// ------------------------------

export const isEqualTime = (d1: Date, d2: Date): boolean => {
  if (!d1 || !d2) return false;
  return d1.getFullYear() == d2.getFullYear() && 
    d1.getMonth() == d2.getMonth() && 
    d1.getDate() == d2.getDate() &&
    d1.getHours() == d2.getHours() &&
    d1.getMinutes() == d2.getMinutes();
};

export const isInToday = (d: Date): boolean => {
  const today = new Date();
  return d.getDate() == today.getDate() &&
    d.getMonth() == today.getMonth() &&
    d.getFullYear() == today.getFullYear();
};

export const isInTomorrow = (d: Date): boolean => {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  return d.getDate() == tomorrow.getDate() &&
    d.getMonth() == tomorrow.getMonth() &&
    d.getFullYear() == tomorrow.getFullYear();
};

export const toHHMMSS = (input): string => {
  if (!input) return null;
  var sec_num = parseInt(input.toString(), 10); // don't forget the second param
  var hours: any   = Math.floor(sec_num / 3600);
  var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds: any = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) { hours   = "0"+hours; }
  if (minutes < 10) { minutes = "0"+minutes; }
  if (seconds < 10) { seconds = "0"+seconds; }
  return hours + ':' + minutes + ':' + seconds;
};

// ------------------------------
// Manipulation
// ------------------------------

export const addDaysToDate = (date: Date, numberOfDays: number): Date => {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numberOfDays);
  return newDate;
};

export const addMinutesToDate = (date: Date, minutes: number): Date => {
  var newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() + minutes);
  return newDate;
};

// ------------------------------
// Formatting
// ------------------------------

export const formatToAPI = (d: Date): string => {
  return d?.toISOString();
}

export const formatDayOfWeekString = (d: Date, options: { relative?: boolean, style?: 'short' | 'long' } = {}): string => {
  const { relative = true, style = 'long' } = options;
  if (relative && isInToday(d)) {
    return "Today";
  } else if (relative && isInTomorrow(d)) {
    return "Tomorrow";
  } else {
    const options: Intl.DateTimeFormatOptions = { weekday: style };
    return d.toLocaleString(locale, options);
  }
};

export const formatTinyDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
  return d.toLocaleString(locale, options);
};

export const formatShortDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
  return d.toLocaleString(locale, options);
};

export const formatLongDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
  return d.toLocaleString(locale, options);
};

export const formatTimeString = (date: Date): string => {
  var options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

  if (timeZoneIsDifferentFromShop()) {
    options = { ...options, timeZone: 'GMT', timeZoneName: 'short' };
  }

  return date.toLocaleTimeString(locale, options);
};

// ------------------------------
// Helpers
// ------------------------------

const timeZoneIsDifferentFromShop = () => {
  return getCurrentTimeZone() !== 'Europe/London';
};

const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};